<style lang="scss" scoped>
form {
  @apply m-auto w-full border-2 border-gray-300 border-dashed rounded-md py-4;
  .dropzone {
    @apply fixed top-0 left-0 w-full h-full  z-20;
  }
  >input {
    display: none;
  }
    @apply flex mt-1;
    button {
      @apply whitespace-no-wrap ml-2 mt-8;
    }
}
</style>

<template>
  <form>
    <div
      :ref="'dropzone'"
      class="dropzone"
      :class="dropzoneActive ? 'block' : 'hidden'"
    />
    <label
      for="file"
      class="flex flex-col justify-center items-center py-4 w-full z-10"
      :class="dropzoneActive ? 'bg-teal-100 bg-opacity-75' : ''"
    >
      <div class="flex items-center justify-center">
        <uiIcon
          name="cloud"
          class="w-10 mx-4 sm:hidden"
          color="neutral"
        />
        <div>
          <h2 class="block text-primary mb-1">{{ form[returnField].label }}</h2>
          <span class="block mb">Clique aqui
            <span class="mobile:hidden">ou arraste o arquivo</span>
          </span>
          <small class="mt-2">formatos permitidos:
            <strong>{{ form[returnField].exts.join(', ') }}</strong>
          </small><br>
        </div>
      </div>

      <input
        :accept="form[returnField].exts"
        :disabled="sending || form[returnField].disabled"
        type="file"
        id="file"
        class="hidden"
        @change="selectFile($event.target.files)"
      >
      <p>
        <small
          class="text-red"
        >{{ error || '&nbsp;' }}</small>
      </p>
    </label>
    <div v-if="submitButton">
      <Submit
        :disabled="$v.form.$invalid"
        :sending="sending"
        action="Carregar"
        icon-before="icss-cloud-download"
        btn-class="btn-default"
        @click="confirmText ? confirm() : submit()"
      />
    </div>
  </form>
</template>

<script>
import formUtils from '@/mixins/formUtils';
import uiIcon from '@/components/ui/Icon.vue';
import Submit from './_submit.vue';

export default {
  components: {
    Submit,
    uiIcon,
  },
  mixins: [formUtils],
  props: {
    confirmText: {
      type: String,
      default: null,
    },
    formData: {
      type: Object,
      required: true,
    },
    submitButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.formData,
      fileName: '',
      error: null,
      sending: false,
      fieldId: 'upload',
      dropzoneActive: false,
      handlers: {
        dropzoneActivate(self) {
          self.dropzoneActive = true;
        },
        dropzoneDeactivate(self) {
          self.dropzoneActive = false;
        },
        dropzoneDrop(e, self) {
          self.dropzoneActive = false;
          if (e.dataTransfer.files.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            self.selectFile(e.dataTransfer.files);
          }
        },
      },
    };
  },
  validations() {
    const obj = { form: this.buildValidations(this.form) };
    return obj;
  },
  computed: {
    returnField() {
      for (const field in this.form) {
        return field;
      }
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
        this.$refs.dropzone.addEventListener(evt, (e) => {
          e.preventDefault();
          e.stopPropagation();
        }, false);
      });

      document.body.addEventListener('dragenter', () => this.handlers.dropzoneActivate(this));
      this.$refs.dropzone.addEventListener('dragleave', () => this.handlers.dropzoneDeactivate(this));
      this.$refs.dropzone.addEventListener('drop', (e) => this.handlers.dropzoneDrop(e, this));

      this.error = false;
    });
  },
  beforeDestroy() {
    document.body.removeEventListener('dragenter', this.handlers.dropzoneActivate);
  },
  methods: {
    confirm() {
      this.fn_OPEN_CONFIRM({
        title: 'Upload de arquivo',
        body: this.confirmText,
        size: 8,
        beforeClose: (v) => {
          if (v) {
            this.fn_CLOSE_CONFIRM();
            this.submit();
          } else {
            this.fn_CLOSE_CONFIRM();
          }
        },
        confirmText: 'Sim, enviar arquivo',
        cancelText: 'Não enviar ainda',
      });
    },
    selectFile(files) {
      if (files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.form[this.returnField].value = files[0];
        this.$v.form.$touch();
        this.fileName = files[0].name;
        if (this.$v.form.$invalid) {
          this.error = 'Formato inválido';
          return;
        }
        this.error = null;
        this.$emit('success', files[0]);

        return;
      }

      this.form[this.returnField].value = null;
      this.fileName = '';
    },
    submit() {
      this.sending = true;
      const data = this.form[this.returnField].value;
      const headers = {
        Authorization: this.$store.getters['auth/getAuth'],
      };
      const boundary = Math.random().toString().substr(2);
      headers['Content-Type'] = `multipart/form-data; charset=utf-8; boundary=${boundary}`;
      const formData = new FormData();
      this.reload = true;
      formData.append('planilha', data);
      this.$http.post('/spreadsheet', formData, headers).then(
        () => {
          this.fn_CLEAR_FORM(this.form);
          this.$emit('success', true);
        },
      ).catch(
        (err) => {
          this.fn_LOG(err);
          this.$emit('failure', true);
        },
      ).finally(
        () => {
          this.$v.form.$reset();
          this.sending = false;
        },
      );
    },
  },
};
</script>
