<template>
  <article
    class="profile-tab__content"
    v-if="ui.loaded"
  >
    <uiProfilePicture
      class="mb-8"
      @success="previewImage($event)"
      :loaded-image="loadedImage"
      :form-data="uploadForm"
    />

    <ui-form
      :ref="'form-profile'"
      class="profile__form"
      :form-data="formData"
      :sending="ui.isLoading"
      :api-errors.sync="apiErrors"
      @valid="formValid = $event"
    />

    <ui-button
      :disabled="ui.isLoading || !formValid"
      width="auto"
      class="mx-auto w-64 mt-4"
      label="Atualizar cadastro"
      @click="submit"
    />
  </article>
</template>

<script>
import formData from '@/data/user/formProfile';
import { mapActions } from 'vuex';
import formUtils from '@/mixins/formUtils';
import uiProfilePicture from '@/components/ui/form/ProfilePicture.vue';
import uiButton from '@/components/ui/Button.vue';
import uiForm from '@/components/ui/form/_form.vue';
import profile from '@/api/user/profile';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import { format, parseISO } from 'date-fns';

export default {
  name: 'UserProfileData',

  components: {
    uiProfilePicture,
    uiForm,
    uiButton,
  },

  mixins: [handleError, handleSuccess, formUtils],

  data() {
    return {
      formData,
      formValid: true,
      recovery: false,
      profile: null,
      apiErrors: {},
      loadedImage: null,
      uploadForm: {
        profileImage: {
          label: 'Foto de perfil',
          value: null,
          type: 'file',
          required: true,
          exts: ['.jpg', '.png', '.gif'],
        },
      },
      ui: {
        loaded: false,
        confirmationActive: false,
        isLoading: false,
      },
      rememberUser: false,
    };
  },

  mounted() {
    this.ui.isLoading = true;
    this.toggleProgressBar(true);
    this.profile = profile(this.$store.getters['user/headers']);
    for (const field in formData) {
      this.$set(this.apiErrors, field, []);
    }
    this.profile.fetch().then(
      (res) => {
        this.populateForm(this.formData, res);

        const iso = res['ddi_contact_phone'] || '55';
        this.formData.ddi.value = this.formData.ddi.options.find(
          (option) => option.code === iso,
        ).iso;

        this.profile.fetchPicture().then(
          (v) => {
            if (v) {
              this.uploadForm.profileImage.value = `/s3/file/user/${res.id}/profile_picture`;
            }
            if (this.formData.dateOfBirth.value) {
              this.formData.dateOfBirth.value = format(parseISO(this.formData.dateOfBirth.value), 'dd/MM/yyyy');
            }
            this.ui.loaded = true;
            this.ui.isLoading = false;
            this.toggleProgressBar(false);
          },
        );
      },
    ).finally(() => {
      this.ui.isLoading = false;
      this.toggleProgressBar(false);
    });
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    previewImage(file) {
      this.loadedImage = true;
      this.uploadForm.profileImage.value = file;
    },

    submit() {
      this.ui.isLoading = true;
      this.toggleProgressBar(true);
      const data = this.expandForm(this.formData);
      data.cpf = data.cpf.replace(/[.-]/g, '');
      data.contactPhone = data.contactPhone.replace(/[ ()-]/g, '');
      data.dateOfBirth = `${data.dateOfBirth.split('/')[2]}-${data.dateOfBirth.split('/')[1]}-${data.dateOfBirth.split('/')[0]}`;
      delete data.cpf;

      data.ddiContactPhone = this.formData.ddi.options.find(
        (option) => option.iso === this.formData.ddi.value,
      ).code;

      delete data.ddi;

      this.profile.update({ user: data }).then(
        () => {
          if (this.loadedImage) {
            this.ui.isLoading = true;
            this.toggleProgressBar(true);
            const data = { 'profile_picture[profilePictureFile][file]': this.uploadForm.profileImage.value };
            this.profile.uploadPicture(data).then(
              (res) => {
                this.handleSuccess('Dados atualizados com sucesso');
                this.actualImage = res;
                this.loadedImage = false;
                this.$emit('reload', true);
              },
            ).catch((err) => console.log(err)).finally(() => {
              this.toggleProgressBar(false);
              this.ui.isLoading = false;
            });
          } else {
            this.handleSuccess('Dados atualizados com sucesso');
            this.ui.isLoading = false;
            this.toggleProgressBar(false);
            this.$emit('reload', true);
          }
        },
      ).catch(
        (err) => {
          this.handleApiErrors(err, this.apiErrors);
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        },
      );
    },
  },
};
</script>
