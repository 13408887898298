<template>
  <button
    :disabled="sending || disabled"
    :type="isSubmit ? 'submit' : 'button'"
    class="button button--primary"
    :class="btnClass"
    @click="$emit('click', true)"
  >
    <i
      v-if="iconBefore"
      :class="getIcon(iconBefore)"
    />
    <span :class="noIcon && sending ? 'invisible' : ''">&ensp;{{ action }}&ensp;</span>
    <transition name="fade">
      <i
        v-if="noIcon && sending"
        class="icss-gear icss-spin !m-0 !absolute"
      />
    </transition>
    <i
      v-if="iconAfter"
      :class="getIcon(iconAfter)"
    />
  </button>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    iconBefore: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: 'button-primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noIcon() {
      return !this.iconBefore && !this.iconAfter;
    },
  },
  methods: {
    getIcon(icon) {
      if (this.sending) {
        return 'icss-gear icss-spin';
      }
      return icon;
    },
  },
};
</script>
