import { isValidPhoneNumber } from 'libphonenumber-js';
import countries from '@/data/countries';

const formData = {
  name: {
    name: 'name',
    type: 'text',
    label: 'Nome Completo',
    placeholder: 'João Silva',
    class: 'md:w-6/12 px-4',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
  },
  gender: {
    name: 'gender',
    type: 'radio',
    label: 'Sexo',
    class: 'md:w-6/12 px-4',
    lazy: true,
    value: null,
    radios: [
      {
        value: 'M',
        label: 'masculino',
      },
      {
        value: 'F',
        label: 'feminino',
      },
    ],
  },
  cpf: {
    name: 'cpf',
    type: 'text',
    label: 'CPF',
    placeholder: '000.000.000-00',
    mask: ['###.###.###-##'],
    class: 'md:w-6/12 px-4',
    lazy: true,
    disabled: true,
    value: null,
    required: true,
  },
  rg: {
    name: 'rg',
    type: 'text',
    label: 'RG',
    placeholder: '00.000.000-0',
    class: 'md:w-6/12 px-4',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
  },
  email: {
    name: 'email',
    type: 'email',
    label: 'E-mail',
    placeholder: 'joao@silva.com.br',
    class: 'md:w-6/12 px-4',
    lazy: false,
    disabled: false,
    value: null,
    required: true,
  },
  ddi: {
    name: 'ddi',
    type: 'select',
    label: 'DDI',
    class: 'md:w-2/12 px-6',
    lazy: false,
    disabled: false,
    value: null,
    required: true,
    options: countries,
  },
  contactPhone: {
    name: 'contactPhone',
    type: 'text',
    label: 'Celular',
    placeholder: '(00) 0000-0000',
    mask: ['(##) 9####-####'],
    class: 'md:w-4/12 px-4',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
    isValidPhoneNumber(number, iso) {
      if (number) {
        return isValidPhoneNumber(number, String(iso).toUpperCase());
      }

      return false;
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    type: 'text',
    label: 'Data de nascimento',
    placeholder: 'dd/mm/YYYY',
    mask: ['##/##/####'],
    class: 'md:w-6/12 px-4',
    lazy: true,
    disabled: false,
    value: null,
  },
  // 'plainPassword.first': {
  //   name: 'plainPassword.first',
  //   type: 'password',
  //   label: 'Senha',
  //   class: 'md:w-6/12 px-4',
  //   lazy: true,
  //   disabled: false,
  //   value: null,
  //   required: true,
  //   isValid(v) {
  //     if (!v) {
  //       return false;
  //     }
  //     if (v.search(/[0-9]/g) === -1 || v.search(/[a-zA-Z]/g) === -1) {
  //       return false;
  //     }
  //     return true;
  //   },
  //   errors: {
  //     isValid: 'Precisa conter um algarismo e uma letra',
  //   },
  // },
  // 'plainPassword.second': {
  //   name: 'plainPassword.second',
  //   type: 'password',
  //   label: 'Confirme a senha',
  //   class: 'md:w-6/12 px-4',
  //   lazy: true,
  //   disabled: false,
  //   sameAs: 'plainPassword.first',
  //   value: null,
  //   required: true,
  // },
};

export default formData;
