<style lang="scss" scoped>
figure {
  @apply relative w-32 h-32 flex p-2 mr-4;
  @apply border-2 border-primary border-dashed box-content rounded-md;
  figcaption {
    @apply absolute bottom-0 w-full right-0 pb-2 px-4;
    @apply text-xs box-border bg-white  bg-opacity-75 text-right cursor-pointer;
  }
}
</style>

<template>
  <div class="w-full">
    <Croppa
      v-if="croppaActive"
      @status="loadCropped($event)"
      :image="preCroppedImage"
    />
    <div class="flex">
      <figure>
        <img
          :style="`background: url(${returnImage}) no-repeat center center contain`"
          v-if="returnImage"
          class="w-full object-contain"
          :src="returnImage"
        >
        <i class="loading" />
        <figcaption
          @click="croppaActive = true"
          v-if="croppedImage"
        >
          <strong>Editar</strong>
        </figcaption>
      </figure>

      <Droppable
        class="mobile:!w-6/12"
        @success="loadImage($event)"
        :form-data="formData"
      />
    </div>
  </div>
</template>

<script>
import formUtils from '@/mixins/formUtils';
import Droppable from '@/components/ui/form/Droppable.vue';
import Croppa from '@/components/modals/Croppa.vue';

export default {
  components: {
    Droppable,
    Croppa,
  },
  mixins: [formUtils],
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      fieldId: 'upload',
      croppaActive: false,
      originalImage: null,
      croppedImage: null,
      preCroppedImage: null,
    };
  },
  computed: {
    returnField() {
      for (const field in this.formData) {
        return field;
      }
      return false;
    },
    returnImage() {
      return this.croppedImage || this.originalImage;
    },
  },
  mounted() {
    if (this.formData[this.returnField].value) {
      this.originalImage = process.env.VUE_APP_BASE_API + this.formData[this.returnField].value;
    }
  },
  methods: {
    loadImage(ev) {
      this.preCroppedImage = ev ? URL.createObjectURL(ev) : false;
      this.croppaActive = ev;
    },
    loadCropped(blob) {
      this.croppaActive = false;
      if (blob) { this.croppedImage = URL.createObjectURL(blob); }
      this.$emit('success', blob);
    },
  },
};
</script>
