<style lang="scss" scoped>
.zoom {
  @apply flex justify-end my-4;
  button {
    @apply rounded-full w-8 h-8 text-white ml-2;
  }
}
</style>

<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      id="modal-id"
      size="w-auto"
    >
      <template v-slot:title>
        Ajuste de foto
      </template>
      <template v-slot:body>
        <div class="content text-center">
          <croppa
            v-model="file"
            :ref="'avatar'"
            :placeholder-font-size="14"
            :accept="'image/*'"
            :width="300"
            :height="300"
            :zoom-speed="40"
            :quality="1"
            :placeholder="'Clique ou arraste uma imagem aqui'"
            @new-image="setImage()"
            @image-remove="clearImage()"
            :key="restart"
            :show-remove-button="false"
            class="cursor-move"
          >
            <img
              slot="initial"
              :src="image"
              v-if="image && hasImage"
            >
            <div class="zoom">
              <button
                class="bg-secondary"
                @mousedown="$refs.avatar.zoomOut()"
              >
                <i class="icon icon-minus" />
              </button>
              <button
                class="bg-primary"
                @mousedown="$refs.avatar.zoomIn()"
              >
                <i class="icon icon-plus" />
              </button>
            </div>
          </croppa>
        </div>
        <div class="modal-footer flex justify-between">
          <button
            type="button"
            @click="dismiss()"
            :disabled="sending"
            :class="sending ? 'disabled' : ''"
            class="button bg-neutral"
          >
            Cancelar
          </button>
          <ui-submit
            :sending="sending"
            @click.native="send()"
            action="Confirmar"
          />
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';
import Croppa from 'vue-croppa';
import uiSubmit from '@/components/ui/form/_submit.vue';

export default {
  components: {
    croppa: Croppa.component,
    uiSubmit,
    Modal,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      restart: 0,
      file: null,
      sending: false,
      hasImage: true,
      disableCroppa: true,
      mouseEvt: null,
      initialImage: null,
    };
  },
  methods: {
    setInterval(f, time) {
      return setInterval(f, time);
    },
    clearInterval(id) {
      return clearInterval(id);
    },
    dismiss() {
      this.$emit('status', false);
    },
    clearImage() {
      this.disableCroppa = false;
      this.hasImage = false;
      this.$refs.avatar.remove();
      setTimeout(() => { // hack para CORS no firefox (e possivelmente outros)
        this.$refs.avatar.refresh();
        this.restart += 1;
      }, 100);
    },

    send() {
      // this.disableCroppa = true;
      // this.sending = true;
      // this.fn_SHOW_PROGRESS();

      this.$refs.avatar.generateBlob((blob) => {
        if (!blob) {
          this.$store.dispatch('setModalAlert', {
            title: 'Erro',
            body: 'Você precisar escolher um arquivo de imagem válido antes de continuar.',
            size: 'md',
            active: true,
          });
          this.disableCroppa = false;
          this.sending = false;
          this.fn_HIDE_PROGRESS();
        } else {
          this.$emit('status', blob);
          return;
        }

        function getFormData(object) {
          const formData = new FormData();
          Object.keys(object).forEach((key) => formData.append(key, object[key]));
          return formData;
        }

        const headers = this.fn_COPY_OBJECT(this.$store.getters.getAuthHeader);
        const boundary = Math.random().toString().substr(2);
        headers.headers['Content-Type'] = `multipart/form-data; charset=utf-8; boundary=${boundary}`;
        blob.lastModifiedDate = new Date();
        blob.name = 'avatar';
        const data = { 'profile_picture[profilePictureFile][file]': blob };
        const fdata = getFormData(data);
        this.$http.post(`/${this.fn_IS_DOCTOR() ? 'doctor' : 'user'}/profile_picture`, fdata, headers).then(
          () => {
            this.$store.dispatch('setModalAlert', {
              title: 'Sucesso',
              body: 'Imagem salva com sucesso',
              size: 'md',
              active: true,
            });
            this.sending = false;
            this.$emit('newImage', this.$refs.avatar.img.src);
            this.fn_HIDE_PROGRESS();
            this.dismiss();
          },
        ).catch(
          (err) => {
            console.log(err);
            this.$store.dispatch('setModalAlert', this.ERROR_MESSAGES.modal.server);
            this.sending = false;
            this.fn_HIDE_PROGRESS();
          },
        );
      }, 'image/png', 100);
    },

  },
};
</script>
